import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/Footer.css'; // Custom CSS for styling

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={4} md={6} className="footer-widget">
            <h5>About Vyntork</h5>
            <p>
              At Vyntork, we are dedicated to accelerating the shift toward electric mobility with cutting-edge EV charging infrastructure. Our vision is to create a connected, sustainable future with reliable and innovative charging solutions across India.
            </p>
          </Col>
          <Col lg={4} md={6} className="footer-widget">
            <h5>Quick Links</h5>
            <ul className="footer-links">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/services">Our Services</Link></li>
              <li><Link to="/solutions">EV Solutions</Link></li>
              <li><Link to="/contact">Get in Touch</Link></li>
            </ul>
          </Col>
          <Col lg={4} md={6} className="footer-widget">
            <h5>Connect with Us</h5>
            <p>
              Stay connected with us on social media and be a part of the green revolution.
            </p>
            <ul className="footer-social">
              <li><a href="https://facebook.com" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://twitter.com" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://instagram.com" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
              <li><a href="https://linkedin.com" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
            </ul>
          </Col>
        </Row>
        <Row className="footer-bottom mt-4">
          <Col md={12} className="text-center">
            <p>&copy; {new Date().getFullYear()} Enego Service Private Limited. All Rights Reserved.</p>
            <p className="footer-privacy">
              <Link to="/Privacypolicy">Privacy Policy</Link> | <Link to="/Termsofservice">Terms of Service</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
