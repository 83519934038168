import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Services from './Pages/Services'; // Import the Key Services page
import Header from './Pages/Header'; // Import the Header component
import Footer from './Pages/Footer'; // Import the Footer component
import PopupForm from './Pages/PopupForm'; // Import the PopupForm component
import PrivacyPolicy from '../src/Pages/Privacypolicy';
import TermsOfService from '../src/Pages/Termsofservice';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css'; // Optional: Your custom App-level styling

function App() {
  const [showPopup, setShowPopup] = useState(false); // Initialize as false

  useEffect(() => {
    // Check if the user has already submitted the form
    const hasSubmitted = localStorage.getItem('hasSubmitted');
    if (!hasSubmitted) {
      setShowPopup(true); // Show the popup if not submitted
    }
  }, []);

  return (
    <Router>
      <div className={`App ${showPopup ? 'blur-background' : ''}`}> {/* Add blur class conditionally */}
        <Header /> {/* Include the Header component */}
        
        {showPopup && (
          <PopupForm 
            onClose={() => {
              setShowPopup(false); // Hide the popup
              localStorage.setItem('hasSubmitted', 'true'); // Mark the form as submitted
            }} 
          />
        )}
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} /> {/* Add route for Key Services page */}
          <Route path="/Privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/Termsofservice" element={<TermsOfService />} />
        </Routes>

        <Footer /> {/* Include the Footer component */}
      </div>
    </Router>
  );
}

export default App;
