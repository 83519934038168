import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/Privacypolicy.css';

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-section">
      <Container>
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <h1 className="privacy-title">Privacy Policy</h1>
            <p className="last-updated">Last updated: [Date]</p>
            <p className="privacy-intro">
              Welcome to Vyntork! At Vyntork, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect the personal information you provide to us through our EV charging solutions and services.
            </p>
          </Col>
        </Row>

        <Row className="policy-content">
          <Col md={12} className="mb-4">
            <h2>1. Information We Collect</h2>
            <p>We collect and process the following types of personal information:</p>
            <ul>
              <li><strong>Personal Information:</strong> Name, contact number, email address, and mailing address collected through our forms.</li>
              <li><strong>Device and Usage Information:</strong> Data related to the device you use to access our site, such as IP address, browser type, and usage data.</li>
            </ul>
          </Col>

          <Col md={12} className="mb-4">
            <h2>2. How We Use Your Information</h2>
            <p>We use your information to:</p>
            <ul>
              <li><strong>Provide and Improve Our Services:</strong> Ensure efficient EV charging solutions and optimize operations.</li>
              <li><strong>Communicate with You:</strong> Respond to inquiries, provide updates, and send relevant promotional materials.</li>
              <li><strong>Enhance User Experience:</strong> Analyze data to improve our website functionality and user experience.</li>
              <li><strong>Marketing Purposes:</strong> Send you offers related to EV solutions, only with your consent.</li>
            </ul>
          </Col>

          <Col md={12} className="mb-4">
            <h2>3. Data Sharing and Disclosure</h2>
            <p>We may share your data with third parties in the following circumstances:</p>
            <ul>
              <li><strong>Service Providers:</strong> Trusted third-party vendors that assist us in operating our business, such as email services.</li>
              <li><strong>Legal Compliance:</strong> In response to legal requests or to comply with regulatory requirements.</li>
              <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred.</li>
            </ul>
          </Col>

          <Col md={12} className="mb-4">
            <h2>4. Data Security</h2>
            <p>
              We implement security measures to protect your personal data from unauthorized access, alteration, or disclosure. However, please note that no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
            </p>
          </Col>

          <Col md={12} className="mb-4">
            <h2>5. Your Rights</h2>
            <p>You have the following rights regarding your personal data:</p>
            <ul>
              <li><strong>Access:</strong> You can request access to your personal data that we hold.</li>
              <li><strong>Correction:</strong> You may request correction of inaccurate or incomplete information.</li>
              <li><strong>Deletion:</strong> You may request deletion of your personal data, subject to legal obligations.</li>
              <li><strong>Opt-Out:</strong> You may opt out of receiving marketing communications at any time.</li>
            </ul>
          </Col>

          <Col md={12} className="mb-4">
            <h2>6. Cookies and Tracking</h2>
            <p>
              Our website uses cookies to improve your browsing experience. You can choose to disable cookies through your browser settings, but please note that some features of our website may not function properly without cookies.
            </p>
          </Col>

          <Col md={12} className="mb-4">
            <h2>7. Third-Party Links</h2>
            <p>
              Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of these third-party sites.
            </p>
          </Col>

          <Col md={12} className="mb-4">
            <h2>8. Children’s Privacy</h2>
            <p>
              Our services are not directed to children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete it.
            </p>
          </Col>

          <Col md={12} className="mb-4">
            <h2>9. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this Privacy Policy periodically for any updates.
            </p>
          </Col>

          <Col md={12} className="mb-4">
            <h2>10. Contact Us</h2>
            <p>
              If you have any questions or concerns regarding this Privacy Policy, please contact us:
            </p>
            <p>
              <strong>Vyntork</strong><br />
              Email: <a href="mailto:info@vyntork.com">info@vyntork.com</a><br />
              Address: NR Hot & Spice, Garmirjapur Barabagan, Andul -mouri, Howrah, Howrah- 711302, West Bengal<br />
              Phone: +91 9433399529
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
