import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import '../css/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    address: '',
    workType: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required.";
    if (!formData.number) formErrors.number = "Number is required.";
    else if (!/^\d{10}$/.test(formData.number))
      formErrors.number = "Number must be 10 digits.";
    if (!formData.email) formErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      formErrors.email = "Email address is invalid.";
    if (!formData.address) formErrors.address = "Address is required.";
    if (!formData.workType) formErrors.workType = "Please select a work type.";
    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const templateParams = {
      name: formData.name,
      number: formData.number,
      email: formData.email,
      address: formData.address,
      workType: formData.workType,
    };

    emailjs
      .send('service_xs4mxiu', 'template_x4odz0f', templateParams, 'tk77O17V56Evj9RjC')
      .then(() => {
        alert('Thank you for reaching out! We will get back to you shortly.');
        setFormData({
          name: '',
          number: '',
          email: '',
          address: '',
          workType: '',
        });
        setErrors({});
      })
      .catch((error) => {
        console.error('Failed to send message:', error);
        alert('There was an error sending your message. Please try again later.');
      });
  };

  return (
    <section className="contact-section">
      <Container>
        {/* Contact Us Heading */}
        <Row className="justify-content-center">
          <Col md={8} className="text-center mb-4">
            <h1>Contact Us</h1>
            <p className="contact-description">
              Have a question about our EV solutions? Fill out the form below, and we will get back to you as soon as possible.
            </p>
          </Col>
        </Row>

        {/* Contact Information Section */}
        <Row className="contact-info">
          <Col lg={4} className="mb-4">
            <div className="contact-card">
              <i className="fa fa-phone contact-icon"></i>
              <h5>Call Us</h5>
              <p>+91 9433399529</p>
            </div>
          </Col>
          <Col lg={4} className="mb-4">
            <div className="contact-card">
              <i className="fa fa-map-marker contact-icon"></i>
              <h5>Our Address</h5>
              <p>Vybrains Energy Pvt Ltd</p>
              <p>Garmirjapur Barabagan,Andul-mouri</p>
              <p>Howrah, West Bengal - 711302</p>
            </div>
          </Col>
          <Col lg={4} className="mb-4">
            <div className="contact-card">
              <i className="fa fa-envelope contact-icon"></i>
              <h5>Email Us</h5>
              <p>info@vintork.com</p>
            </div>
          </Col>
        </Row>

        {/* Contact Form Section */}
        <Row className="justify-content-center">
          <Col lg={8}>
            <Form onSubmit={handleSubmit} className="contact-form">
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </Form.Group>
              <Form.Group controlId="formNumber" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Your Number"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  required
                />
                {errors.number && <p className="error">{errors.number}</p>}
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </Form.Group>
              <Form.Group controlId="formAddress" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Your Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
                {errors.address && <p className="error">{errors.address}</p>}
              </Form.Group>
              <Form.Group controlId="formWorkType" className="mb-3">
                <Form.Control
                  as="select"
                  name="workType"
                  value={formData.workType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a work type</option>
                  <option value="EV Charging Station">EV Charging Station</option>
                  <option value="EV Power Bank">EV Power Bank</option>
                  <option value="EV Service Center Franchise">EV Service Center Franchise</option>
                  <option value="Hybrid Car Service Franchise">Hybrid Car Service Franchise</option>
                  <option value="Hybrid Fueling Station Apply">Hybrid Fueling Station Apply</option>
                  <option value="Hybrid Fueling Car Bank">Hybrid Fueling Car Bank</option>
                </Form.Control>
                {errors.workType && <p className="error">{errors.workType}</p>}
              </Form.Group>
              <Button type="submit" className="contact-btn">
                Send Message
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
