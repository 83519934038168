// Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../css/Header.css'; // Custom CSS for additional styling
import logo from '../assets/logo.png'; // Adjust the path if necessary

const Header = () => {
  return (
    <header>
      <Navbar expand="lg" className="navbar-custom fixed-top">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="navbar-brand-custom">
            <img src={logo} alt="Ventork Logo" className="logo-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={NavLink} to="/" className="nav-link-custom" activeClassName="active-nav">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/about" className="nav-link-custom" activeClassName="active-nav">
                About Us
              </Nav.Link>
              <Nav.Link as={NavLink} to="/services" className="nav-link-custom" activeClassName="active-nav">
                Key Services
              </Nav.Link> {/* New link for Key Services */}
              <Nav.Link as={NavLink} to="/contact" className="nav-link-custom" activeClassName="active-nav">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
