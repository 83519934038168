import React from 'react';
import '../css/Gallery.css'; // Import the gallery-specific CSS

// Array of images to display in the gallery
import img1 from '../assets/gallery1.webp';
import img2 from '../assets/gallery2.webp';
import img3 from '../assets/gallery3.webp';

const Gallery = () => {
  const images = [img1, img2, img3]; // Use imported images

  return (
    <div className="gallery-container">
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          <img src={image} alt={`Gallery image ${index + 1}`} className="gallery-image" />
        </div>
      ))}
    </div>
  );
};

export default Gallery;
