import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup'; // Import CountUp library
import '../css/About.css'; // Import custom CSS for additional styling
import aboutImage from '../assets/Aboutus.webp'; // Import the About Us image

const About = () => {
  return (
    <section className="about-section">
      <Container>
        <Row className="align-items-center mb-5">
          <Col md={6}>
            <div className="about-image">
              <img src={aboutImage} alt="About Vyntork" className="img-fluid rounded" />
            </div>
          </Col>
          <Col md={6}>
            <div className="about-content">
              <h1>About Vyntork</h1>
              <p>
                Vyntork is leading the charge in transforming India’s electric vehicle (EV) infrastructure. 
                We are dedicated to building a sustainable future through innovative EV charging solutions and energy management systems 
                that facilitate the transition to electric mobility across the nation.
              </p>
              <p>
                Since our founding, we have rapidly expanded our network of EV charging stations across major cities in India, 
                providing cleaner transportation options while significantly reducing carbon emissions. Our mission is to ensure electric 
                mobility is accessible, reliable, and efficient for all users.
              </p>
              <a href="/services" className="btn btn-primary btn-learn-more">Discover Our Solutions</a>
            </div>
          </Col>
        </Row>

        <Row className="mission-vision-section">
          <Col md={6} className="mb-4">
            <div className="mission-card">
              <i className="fa fa-bullseye mission-icon"></i>
              <h3>Our Mission</h3>
              <p>
                To accelerate the adoption of electric vehicles by providing cutting-edge charging infrastructure 
                and energy solutions that support a greener, sustainable future for India.
              </p>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div className="vision-card">
              <i className="fa fa-eye vision-icon"></i>
              <h3>Our Vision</h3>
              <p>
                A world where electric mobility is the standard, powered by clean energy, with a robust charging infrastructure 
                that eliminates barriers to EV adoption.
              </p>
            </div>
          </Col>
        </Row>

        {/* Achievements Section */}
        <Row className="text-center achievements-heading">
          <Col>
            <h2>Our Achievements</h2>
            <p>Since our inception, we have made significant strides in expanding EV infrastructure and facilitating India’s transition to clean energy.</p>
          </Col>
        </Row>

        {/* Achievements Section with Counters */}
        <Row className="achievements-section text-center">
          <Col md={4}>
            <div className="achievement-card">
              <h2>
                <CountUp start={0} end={250} duration={3} separator="," />
              </h2>
              <p>EV Charging Stations Installed</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="achievement-card">
              <h2>
                <CountUp start={0} end={200} duration={3} separator="," />
              </h2>
              <p>Energy Partnerships Established</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="achievement-card">
              <h2>
                <CountUp start={0} end={60000} duration={3} separator="," />
              </h2>
              <p>Carbon Emissions Reduced (in tons)</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
