import React from 'react';
import '../css/Services.css';

const Services = () => {
    return (
        <section className="key-services">
            <div className="container">
                <h2 className="section-title">Key Services</h2>
                <div className="services-grid">
                    <div className="service-card">
                        <i className="service-icon fa fa-bolt"></i>
                        <h3>EV Charging Station Assembly</h3>
                        <p>Professional assembly and installation of EV charging stations to ensure seamless operation and user convenience.</p>
                    </div>
                    <div className="service-card">
                        <i className="service-icon fa fa-car"></i>
                        <h3>EV Car Service & Maintenance</h3>
                        <p>Expert maintenance and repair services for electric vehicles to keep them in top performance condition.</p>
                    </div>
                    <div className="service-card">
                        <i className="service-icon fa fa-truck"></i>
                        <h3>Vintork Zapvan Installation</h3>
                        <p>Specialized installation of Vintork Zapvans for efficient mobile EV charging solutions.</p>
                    </div>
                    <div className="service-card">
                        <i className="service-icon fa fa-sun"></i>
                        <h3>Bright Future Service</h3>
                        <p>Innovative energy solutions to power a sustainable future, combining renewable sources with cutting-edge technology.</p>
                    </div>
                    <div className="service-card">
                        <i className="service-icon fa fa-gas-pump"></i>
                        <h3>Hybrid Fueling Station Assembly</h3>
                        <p>Complete assembly and setup of hybrid fueling stations, blending traditional and electric energy sources.</p>
                    </div>
                    <div className="service-card">
                        <i className="service-icon fa fa-wrench"></i>
                        <h3>Hybrid Car Servicing & Maintenance</h3>
                        <p>Comprehensive service and maintenance for hybrid cars, ensuring optimal performance and durability.</p>
                    </div>
                    <div className="service-card">
                        <i className="service-icon fa fa-building"></i>
                        <h3>Construction</h3>
                        <p>Expert construction services for EV infrastructure, hybrid stations, and other energy-efficient facilities.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
