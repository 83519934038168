import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Container, Row, Col } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import '../css/EVStationsMap.css';

const EVStationsMap = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [stations] = useState([
    { id: 1, name: "Chahal Chowk", position: { lat: 22.5726, lng: 88.3639 }, address: "Hanumangarh Road, Chahal Chowk, Vikash Nagar, Naya..." },
    { id: 2, name: "Housing Board", position: { lat: 22.5679, lng: 88.3740 }, address: "No.98, Kolkata Road, Housing Board, Pali..." },
    { id: 3, name: "Jaisalmer Road", position: { lat: 22.5645, lng: 88.3670 }, address: "A-05, Jaisalmer Road, Murlidhar Vyas Colony..." },
  ]);

  const filteredStations = stations.filter(station =>
    station.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    station.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container className="ev-stations-map">
      {/* Search Bar and Title */}
      <Row>
        <Col>
          <h2>EV Charging Solution</h2>
          <input
            type="text"
            placeholder="Search any charging station"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control mb-2"
          />

          {/* Leaflet Map centered on Kolkata */}
          <MapContainer center={[22.5726, 88.3639]} zoom={12} style={{ width: '100%', height: '400px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {filteredStations.map(station => (
              <Marker key={station.id} position={station.position}>
                <Popup>
                  <strong>{station.name}</strong><br />{station.address}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Col>
      </Row>

      {/* Charging Stations List */}
      <Row className="charging-station-list">
        <Col>
          <h3>EV Charging Stations</h3>
          <Row>
            {filteredStations.map(station => (
              <Col md={4} key={station.id}>
                <div className="charging-station-card">
                  <h4>Operated by Ventork</h4>
                  <p>{station.name}</p>
                  <p>{station.address}</p>
                  <p>Tariff Unknown</p>
                </div>
              </Col>
            ))}
          </Row>
          
          {/* Permanent Message */}
          <div className="no-stations-message">
            <p>There are currently no EV charging stations available in your area.</p>
            <p>Future plans include expanding our network to reach even more locations. Stay tuned as we work towards creating accessible and reliable EV charging solutions near you!</p>
          </div>
        </Col>
      </Row>

    </Container>
  );
};

export default EVStationsMap;
