import React from 'react';
import '../css/EVChargingBenefits.css'; // Import the CSS file for styling
import image1 from '../assets/Choose1.webp'; // Replace with your actual image path
import image2 from '../assets/Choose3.webp'; // Replace with your actual image path
import image3 from '../assets/Choose5.jpg'; // Replace with your actual image path
import chartImage from '../assets/Choose4.webp'; // Replace with your actual chart image path

const EVChargingBenefits = () => {
  return (
    <div className="ev-benefits-container">
      {/* 1st Div - Image with text (Rectangle) */}
      <div className="ev-benefit-card rectangle">
        <img src={image1} alt="EV Charging" className="benefit-image" />
        <div className="benefit-text">
          <h3>Why Evcharge</h3>
          <p>Scalable Charging Solutions that Grow with you.</p>
        </div>
      </div>
      
      {/* 3rd Div - Image with text (Square) */}
      <div className="ev-benefit-card image-card square">
        <img src={image2} alt="Charging Station" className="benefit-image" />
        <h3>Charging stations installed worldwide.</h3>
      </div>
      
      {/* 5th Div - Background color with text and chart (Square) */}
      <div className="ev-benefit-card chart-card square">
        <h1>80%</h1>
        <p>Charging stations installed worldwide.</p>
        <img src={chartImage} alt="Chart" className="benefit-image" />
      </div>
      
      {/* 6th Div - Image with text (Rectangle) */}
      <div className="ev-benefit-card text-image-card rectangle">
        <h3>Optimize your energy usage and save on costs.</h3>
        <img src={image3} alt="Energy Optimization" className="benefit-image" />
      </div>
    </div>
  );
};

export default EVChargingBenefits;
