import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/PopupForm.css';

const PopupForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    address: '',
  });
  
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required.";
    if (!formData.number) formErrors.number = "Number is required.";
    else if (!/^\d{10}$/.test(formData.number)) formErrors.number = "Number must be 10 digits.";
    if (!formData.email) formErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = "Email address is invalid.";
    if (!formData.address) formErrors.address = "Address is required.";
    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const templateParams = {
        name: formData.name,
        number: formData.number,
        email: formData.email,
        address: formData.address,
      };

      emailjs.send('service_xs4mxiu', 'template_djctc5q', templateParams, 'tk77O17V56Evj9RjC')
        .then(() => {
          localStorage.setItem('userData', JSON.stringify(formData));
          onClose();
          alert('Thank you for your submission!');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('There was an error sending your message. Please try again later.');
        });
    }
  };

  return (
    <div className="glass">
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>You’re a New User!</h2>
          <p>Please complete this form to get started.</p>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div>
              <input
                type="text"
                name="number"
                placeholder="Your Number (10 Digits)"
                value={formData.number}
                onChange={handleChange}
                required
              />
              {errors.number && <p className="error">{errors.number}</p>}
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div>
              <input
                type="text"
                name="address"
                placeholder="Your Address"
                value={formData.address}
                onChange={handleChange}
                required
              />
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupForm;
