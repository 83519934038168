import React from 'react';
import { Container, Row, Col, Button, Image, ListGroup } from 'react-bootstrap';
import '../css/Home.css'; // Import custom CSS for styling
import heroImage from '../assets/Hero.png'; // Import your local image
import driverImage1 from '../assets/1.webp';
import driverImage2 from '../assets/2.webp';
import driverImage3 from '../assets/3.webp';
import PortableEVStation1 from '../assets/portable EV.webp';
import PortableEVStation2 from '../assets/Portable Ev2.webp';
import Gallery from './Gallery';
import { useNavigate } from 'react-router-dom';
import { FaBolt, FaTools, FaBatteryFull, FaIdCard } from 'react-icons/fa'; // Import icons for enhancement
import EVStationsMap from './EVStationsMap';
import EVChargingBenefits from './EVChargingBenefits';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Parallax Hero Section */}
      <div className="hero-section" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-text">
          <h1 className="hero-title">Welcome to Vyntork</h1>
          <p className="hero-subtitle">
            At Vyntork, we are dedicated to transforming <br /> transportation 
            by providing state-of-the-art <br /> electric vehicle charging solutions.
          </p>
          <Button className="cta-button">Get Started</Button>
        </div>
      </div>

      {/* Driver Benefits Section */}
      <section className="driver-benefits-section">
        <Container>
          <Row>
            <Col md={4} className="mb-4">
              <div className="benefit-card">
                <img src={driverImage1} alt="Charging You Can Trust" className="img-fluid" />
                <h3>Charging You Can Trust</h3>
                <p>
                  Vyntork provides a reliable charging network to ensure your EV is ready when you are.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="benefit-card">
                <img src={driverImage2} alt="Easy To Find & Easy To Use" className="img-fluid" />
                <h3>Easy To Find & Easy To Use</h3>
                <p>
                  Our user-friendly app helps you locate charging stations with ease and convenience.
                </p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="benefit-card">
                <img src={driverImage3} alt="No Hidden Costs" className="img-fluid" />
                <h3>No Hidden Costs</h3>
                <p>
                  Transparent pricing with no hidden fees. Know exactly what you’re paying for.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* EV Stations Map Section */}
      <EVStationsMap />

      {/* Benefits of EV Charging Section */}
      <section className="benefits-section">
        <h1>Benefits of EV Charging</h1>
        <EVChargingBenefits />
      </section>

      {/* What We Do Section */}
      <section className="what-we-do-section">
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={8}>
              <h2><FaBolt /> What We Do</h2>
              <p className="what-we-do-description">
                Vyntork specializes in EV charging station setup, energy management, and sustainable energy solutions. We’re committed to accelerating the transition to electric mobility and clean energy solutions.
              </p>
              <p className="additional-service-description">
                Our innovative Car Portable EV Station Solution ensures that you’re never stranded without power, providing reliable, mobile charging wherever you are.
              </p>
              <ListGroup className="mt-4 what-we-do-list">
                <ListGroup.Item><FaTools /> EV Charging Station Assembly & Installation</ListGroup.Item>
                <ListGroup.Item><FaBatteryFull /> Car Power Bank Installation</ListGroup.Item>
                <ListGroup.Item><FaIdCard /> Apply for EV Car Owner Card</ListGroup.Item>
              </ListGroup>
              <Button variant="primary" className="btn btn-what-we-do mt-3" onClick={() => navigate('/services')}>Explore Our Services</Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Image src={PortableEVStation1} alt="Portable EV Station in Use" fluid />
            </Col>
            <Col md={6}>
              <Image src={PortableEVStation2} alt="Close-up of Portable EV Charger" fluid />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Gallery Section */}
      <section className="gallery-section">
        <h2>Gallery</h2>
        <Gallery />
      </section>
    </div>
  );
};

export default Home;
