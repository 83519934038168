// TermsOfService.js
import React from 'react';
import { Container } from 'react-bootstrap';
import '../css/Termsofservice.css'; // Custom CSS for styling

const TermsOfService = () => {
  return (
    <Container className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>Last Updated: [Date]</p>
      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Vyntork Energy Solution Private Limited (“Company”, “we”, “our”, “us”). 
          By using our website and services, you agree to comply with and be bound by the following terms 
          and conditions (“Terms of Service”). Please read these Terms carefully, and contact us if you have any questions.
        </p>
      </section>

      <section>
        <h2>2. Eligibility</h2>
        <p>
          By accessing and using our services, you confirm that you are at least 18 years of age and capable 
          of entering into a legally binding agreement. If you are using our services on behalf of a company 
          or other entity, you represent and warrant that you are authorized to bind that entity to these Terms.
        </p>
      </section>

      <section>
        <h2>3. Use of Services</h2>
        <p>
          You agree to use our services only for lawful purposes and in accordance with these Terms. You are 
          prohibited from using our services to engage in any activity that may harm the rights of others, 
          violate any laws, or compromise the security and functionality of our services.
        </p>
      </section>

      <section>
        <h2>4. Intellectual Property</h2>
        <p>
          All content, logos, trademarks, and other intellectual property on our website and services are 
          the property of Vyntork Energy Solution Private Limited or our licensors. You may not use, reproduce, 
          or distribute our intellectual property without prior written consent.
        </p>
      </section>

      <section>
        <h2>5. Account Responsibilities</h2>
        <p>
          If you create an account with us, you are responsible for maintaining the confidentiality of your account 
          credentials and for all activities that occur under your account. Notify us immediately of any unauthorized 
          use of your account.
        </p>
      </section>

      <section>
        <h2>6. Disclaimers</h2>
        <p>
          Our services are provided on an “as is” and “as available” basis. Vyntork Energy Solution Private Limited 
          disclaims all warranties, express or implied, including, but not limited to, implied warranties of 
          merchantability, fitness for a particular purpose, and non-infringement.
        </p>
      </section>

      <section>
        <h2>7. Limitation of Liability</h2>
        <p>
          In no event shall Vyntork Energy Solution Private Limited, its directors, employees, or agents, be liable for 
          any indirect, incidental, special, or consequential damages arising out of or in connection with your use 
          of our services. Our total liability, if any, shall not exceed the amount you have paid to us, if applicable.
        </p>
      </section>

      <section>
        <h2>8. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to our services at our sole discretion, without notice, 
          if we believe that you have violated these Terms or engaged in behavior that is harmful to our interests 
          or the interests of other users.
        </p>
      </section>

      <section>
        <h2>9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to 
          its conflict of law principles. Any disputes arising out of or related to these Terms shall be resolved in the 
          courts of [Jurisdiction].
        </p>
      </section>

      <section>
        <h2>10. Changes to Terms</h2>
        <p>
          Vyntork Energy Solution Private Limited reserves the right to modify these Terms at any time. We will notify 
          you of any changes by posting the new Terms on this page. Your continued use of our services after such 
          modifications constitutes your acceptance of the new Terms.
        </p>
      </section>

      <section>
        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms of Service, please contact us at:
        </p>
        <p>Email: support@vyntork.com</p>
        <p>Address: Vyntork Energy Solution Private Limited, [Office Address]</p>
      </section>
    </Container>
  );
};

export default TermsOfService;
